import { useState, useEffect } from "react";
import { Constants } from "./Constant";


export const useStaticPageData = (endpoint) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 12000);

    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError("");
        const response = await fetch(
          `${Constants.backendUrl}${endpoint}`,
          {
            headers: {
              Authorization: `Basic ${Constants.token}`,
              "Content-Type": "application/json",
            },
            signal: controller.signal,
          }
        );
        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error("Something went wrong");
        }

        const jsonData = await response.json();
        if (jsonData.code === 401) throw new Error("Data Not Found");
        setData(jsonData);
        setError("");
      } catch (error) {
        if (error.name === "AbortError") {
          setError("Request timed out. Please try again later.");
        } else if (error.message === "Failed to fetch") {
          setError("Failed to fetch. Please try again later.");
        } else {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    // return () => {
    //   clearTimeout(timeoutId);
    //   controller.abort();
    // };
  }, [endpoint]);

  return { data, isLoading, error };
};
