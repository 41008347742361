import { createContext, useState, useEffect, useCallback } from "react";
import { Constants } from "../../Middleware/Constant";
import useFetchContextData from "./useFetchContextData";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { data } = useFetchContextData(Constants.backendUrl + "sidebar");
  const {
    data: breakingNews,
    isLoading: isloading1,
    error: error1,
  } = useFetchContextData(Constants.backendUrl + "breaking-news");
  const {
    data: homeNews,
    isLoading: isLoading2,
    error: error2,
  } = useFetchContextData(Constants.backendUrl + "flash-news");

  const [user, setUser] = useState(() => {
    // Load persisted data from localStorage if available
    const userData = localStorage.getItem("ATuserData");
    return userData ? JSON.parse(userData) : null;
  });

  return (
    <DataContext.Provider
      value={{
        data,
        homeNews,
        breakingNews,
        isloading1,
        error1,
        isLoading2,
        error2,
        user,
        setUser,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
