/* eslint-disable */

import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { lazy, Suspense } from "react";
import img from "../src/assets/img/Asian-Trader-bg.png";
const Header = lazy(() => import("./Component/Header/Header"));
const Home = lazy(() => import("./Pages/Home/Home"));
const Footer = lazy(() => import("./Component/Footer/Footer"));
const Magzine = lazy(() => import("./Pages/Static_Pages/Magzine"));
const MediaPack = lazy(() => import("./Pages/Static_Pages/MediaPack"));
const AboutUs = lazy(() => import("./Pages/Static_Pages/AboutUs"));
const ContactUs = lazy(() => import("./Pages/Static_Pages/Contact"));
const AdvertiseWithUs = lazy(() =>
  import("./Pages/Static_Pages/AdvertiseWithUs")
);
const PostPage = lazy(() => import("./Pages/Dynamic_Pages/PostPage"));
const PrivacyPolicy = lazy(() => import("./Pages/Static_Pages/PrivacyPolicy"));
const TermsCondition = lazy(() =>
  import("./Pages/Static_Pages/TermsCondition")
);
const CategoryPage = lazy(() => import("./Pages/Dynamic_Pages/CategoryPage"));
const NotFound = lazy(() => import("./Pages/NotFound"));
const SearchPage = lazy(() => import("./Pages/Dynamic_Pages/SearchPage"));
import "./App.css";
import ScrollToTop from "./Middleware/ScrollToTopRoute";
import { DataProvider } from "./Component/Context/SidebarContext";
import { HelmetProvider } from "react-helmet-async";
import Loader from "./Component/UI/Loader";
import SiteMap from "./Pages/SiteMap";

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <DataProvider>
          <Suspense
            fallback={
              <div className="absolute left-[50%] translate-x-[-50%] translate-y-[-50%] top-[50%]">
                <Loader />
              </div>
            }
          >
            <MainContent />
          </Suspense>
        </DataProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

function MainContent() {
  const location = useLocation();

  const handleBackgroundClick = () => {
    window.open("https://www.calwildrice.org/", "_blank");
  };

  return (
    // <div
    //   style={{
    //     backgroundPositionX: "center",
    //   }}
    //   className={`${
    //     location.pathname === "/"
    //       ? "xl:bg-[url(../src/assets/img/Asian-Trader-bg.png)] h-[100vh] bg-none bg-no-repeat bg-fixed  relative"
    //       : ""
    //   } xl:overflow-hidden`}
    // >
    //   {/* Clickable background layer */}
    //   {location.pathname === "/" && (
    //     <div
    //       onClick={handleBackgroundClick}
    //       className="absolute top-0 left-0 w-full h-full z-0 cursor-pointer"
    //     />
    //   )}
    //   <div
    //     className={`${
    //       location.pathname === "/"
    //         ? "xl:h-[100%] 2xl:h-[100%] xl:mt-40 xl:pb-40 z-50 mx-auto container relative appScroll xl:overflow-scroll"
    //         : ""
    //     }`}
    //   >
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category/:sectionType" element={<CategoryPage />} />
        <Route path="/:post" element={<PostPage />} />
        <Route path="/digital-archive" element={<Magzine />} />
        <Route path="/media-pack" element={<MediaPack />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsCondition />} />
        <Route path="/advertise" element={<AdvertiseWithUs />} />
        <Route path="/sitemap" element={<SiteMap />} />
        <Route path="/s" element={<SearchPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
    // {/* </div> */}
    // {/* </div> */}
  );
}
